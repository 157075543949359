export { default as AlarmIcon } from './flip-ui-kit/small/Alarm';
export { default as AlarmWarningIcon } from './flip-ui-kit/small/AlarmWarning';
export { default as AlertIcon } from './flip-ui-kit/small/Alert';
export { default as AnnotationDotsIcon } from './flip-ui-kit/small/AnnotationDots';
export { default as AnnotationIcon } from './flip-ui-kit/small/Annotation';
export { default as AnnotationWarningIcon } from './flip-ui-kit/small/AnnotationWarning';
export { default as AnnouncementIcon } from './flip-ui-kit/small/Announcement';
export { default as ArrowBackIcon } from './flip-ui-kit/small/ArrowBack';
export { default as ArrowIcon } from './flip-ui-kit/small/Arrow';
export { default as AwardIcon } from './flip-ui-kit/small/Award';
export { default as BadgeIcon } from './flip-ui-kit/small/Badge';
export { default as BankNoteIcon } from './flip-ui-kit/small/BankNote';
export { default as BellIcon } from './flip-ui-kit/small/Bell';
export { default as BlockOutsideIcon } from './flip-ui-kit/small/BlockOutside';
export { default as BoltIcon } from './flip-ui-kit/small/Bolt';
export { default as BookIcon } from './flip-ui-kit/small/Book';
export { default as BookmarkIcon } from './flip-ui-kit/small/Bookmark';
export { default as BooksIcon } from './flip-ui-kit/small/Books';
export { default as BoxIcon } from './flip-ui-kit/small/Box';
export { default as BridgeIcon } from './flip-ui-kit/small/Bridge';
export { default as BrokerIcon } from './flip-ui-kit/small/Broker';
export { default as BugIcon } from './flip-ui-kit/small/Bug';
export { default as CalendarIcon } from './flip-ui-kit/small/Calendar';
export { default as ChartCheckIcon } from './flip-ui-kit/small/ChartCheck';
export { default as ChartIcon } from './flip-ui-kit/small/Chart';
export { default as ChatIcon } from './flip-ui-kit/small/Chat';
export { default as CheckCircleIcon } from './flip-ui-kit/small/CheckCircle';
export { default as CheckmarkIcon } from './flip-ui-kit/small/Checkmark';
export { default as ChevronIcon } from './flip-ui-kit/small/Chevron';
export { default as ClipboardFailIcon } from './flip-ui-kit/small/ClipboardFail';
export { default as ClipboardIcon } from './flip-ui-kit/small/Clipboard';
export { default as ClockIcon } from './flip-ui-kit/small/Clock';
export { default as CloseCircleIcon } from './flip-ui-kit/small/CloseCircle';
export { default as ClosedBookIcon } from './flip-ui-kit/small/ClosedBook';
export { default as CloseIcon } from './flip-ui-kit/small/Close';
export { default as CodeIcon } from './flip-ui-kit/small/Code';
export { default as CoinsIcon } from './flip-ui-kit/small/Coins';
export { default as ConstructionIcon } from './flip-ui-kit/small/Construction';
export { default as ContractCallIcon } from './flip-ui-kit/small/ContractCall';
export { default as ContractFailIcon } from './flip-ui-kit/small/ContractFail';
export { default as ContractSuccessIcon } from './flip-ui-kit/small/ContractSuccess';
export { default as CopyIcon } from './flip-ui-kit/small/Copy';
export { default as CrowdIcon } from './flip-ui-kit/small/Crowd';
export { default as CrownIcon } from './flip-ui-kit/small/Crown';
export { default as DashboardIcon } from './flip-ui-kit/small/Dashboard';
export { default as DiscordIcon } from './flip-ui-kit/small/Discord';
export { default as DistanceIcon } from './flip-ui-kit/small/Distance';
export { default as DocIcon } from './flip-ui-kit/small/Doc';
export { default as Dollar2Icon } from './flip-ui-kit/small/Dollar2';
export { default as DollarIcon } from './flip-ui-kit/small/Dollar';
export { default as DotsHorizontalIcon } from './flip-ui-kit/small/DotsHorizontal';
export { default as DotsIcon } from './flip-ui-kit/small/Dots';
export { default as DotsVerticalIcon } from './flip-ui-kit/small/DotsVertical';
export { default as DownloadIcon } from './flip-ui-kit/small/Download';
export { default as DropletIcon } from './flip-ui-kit/small/Droplet';
export { default as EditIcon } from './flip-ui-kit/small/Edit';
export { default as EducationIcon } from './flip-ui-kit/small/Education';
export { default as EmojiHappyIcon } from './flip-ui-kit/small/EmojiHappy';
export { default as EmojiNeutralIcon } from './flip-ui-kit/small/EmojiNeutral';
export { default as EmojiSadIcon } from './flip-ui-kit/small/EmojiSad';
export { default as EthereumIcon } from './flip-ui-kit/small/Ethereum';
export { default as ExperimentIcon } from './flip-ui-kit/small/Experiment';
export { default as EyeIcon } from './flip-ui-kit/small/Eye';
export { default as EyeHideIcon } from './flip-ui-kit/small/EyeHide';
export { default as FeeIcon } from './flip-ui-kit/small/Fee';
export { default as FileIcon } from './flip-ui-kit/small/File';
export { default as FileTextIcon } from './flip-ui-kit/small/FileText';
export { default as FilterIcon } from './flip-ui-kit/small/Filter';
export { default as FingerSwipeIcon } from './flip-ui-kit/small/FingerSwipe';
export { default as FireIcon } from './flip-ui-kit/small/Fire';
export { default as FlagIcon } from './flip-ui-kit/small/Flag';
export { default as FlareIcon } from './flip-ui-kit/small/Flare';
export { default as FloppyDiscIcon } from './flip-ui-kit/small/FloppyDisc';
export { default as ForbidIcon } from './flip-ui-kit/small/Forbid';
export { default as GasIcon } from './flip-ui-kit/small/Gas';
export { default as GavelIcon } from './flip-ui-kit/small/Gavel';
export { default as GiftIcon } from './flip-ui-kit/small/Gift';
export { default as GithubIcon } from './flip-ui-kit/small/Github';
export { default as GlobeIcon } from './flip-ui-kit/small/Globe';
export { default as GridIcon } from './flip-ui-kit/small/Grid';
export { default as HamburgerIcon } from './flip-ui-kit/small/Hamburger';
export { default as HeartIcon } from './flip-ui-kit/small/Heart';
export { default as HelpCircleIcon } from './flip-ui-kit/small/HelpCircle';
export { default as HelpIcon } from './flip-ui-kit/small/Help';
export { default as HomeIcon } from './flip-ui-kit/small/Home';
export { default as HourglassIcon } from './flip-ui-kit/small/Hourglass';
export { default as IceIcon } from './flip-ui-kit/small/Ice';
export { default as InformationIcon } from './flip-ui-kit/small/Information';
export { default as KeyholeIcon } from './flip-ui-kit/small/Keyhole';
export { default as KeyIcon } from './flip-ui-kit/small/Key';
export { default as KnifeIcon } from './flip-ui-kit/small/Knife';
export { default as LayersIcon } from './flip-ui-kit/small/Layers';
export { default as LightBulbIcon } from './flip-ui-kit/small/LightBulb';
export { default as LinkIcon } from './flip-ui-kit/small/Link';
export { default as LockIcon } from './flip-ui-kit/small/Lock';
export { default as LockUnlockedIcon } from './flip-ui-kit/small/LockUnlocked';
export { default as MaximizeIcon } from './flip-ui-kit/small/Maximize';
export { default as MenuIcon } from './flip-ui-kit/small/Menu';
export { default as MessageIcon } from './flip-ui-kit/small/Message';
export { default as MinimizeIcon } from './flip-ui-kit/small/Minimize';
export { default as MinusCircleIcon } from './flip-ui-kit/small/MinusCircle';
export { default as MinusIcon } from './flip-ui-kit/small/Minus';
export { default as MoneyBagIcon } from './flip-ui-kit/small/MoneyBag';
export { default as MoveIcon } from './flip-ui-kit/small/Move';
export { default as NodeIcon } from './flip-ui-kit/small/Node';
export { default as NoSearchIcon } from './flip-ui-kit/small/NoSearch';
export { default as PageFlipIcon } from './flip-ui-kit/small/PageFlip';
export { default as PaperclipIcon } from './flip-ui-kit/small/Paperclip';
export { default as PartyIcon } from './flip-ui-kit/small/Party';
export { default as PencilIcon } from './flip-ui-kit/small/Pencil';
export { default as PercentageIcon } from './flip-ui-kit/small/Percentage';
export { default as PiggybankIcon } from './flip-ui-kit/small/Piggybank';
export { default as PiggyIcon } from './flip-ui-kit/small/Piggy';
export { default as PinTackIcon } from './flip-ui-kit/small/PinTack';
export { default as PlusCircleIcon } from './flip-ui-kit/small/PlusCircle';
export { default as PlusIcon } from './flip-ui-kit/small/Plus';
export { default as PowerIcon } from './flip-ui-kit/small/Power';
export { default as PulseIcon } from './flip-ui-kit/small/Pulse';
export { default as PuzzleIcon } from './flip-ui-kit/small/Puzzle';
export { default as QRIcon } from './flip-ui-kit/small/QR';
export { default as ReceiptIcon } from './flip-ui-kit/small/Receipt';
export { default as RedditIcon } from './flip-ui-kit/small/Reddit';
export { default as Refresh2Icon } from './flip-ui-kit/small/Refresh2';
export { default as RefreshIcon } from './flip-ui-kit/small/Refresh';
export { default as RightDoubleIcon } from './flip-ui-kit/small/RIghtDouble';
export { default as RobotIcon } from './flip-ui-kit/small/Robot';
export { default as RocketIcon } from './flip-ui-kit/small/Rocket';
export { default as Rotate2Icon } from './flip-ui-kit/small/Rotate2';
export { default as RouteIcon } from './flip-ui-kit/small/Route';
export { default as RssFeedIcon } from './flip-ui-kit/small/RssFeed';
export { default as ScaleIcon } from './flip-ui-kit/small/Scale';
export { default as ScrollIcon } from './flip-ui-kit/small/Scroll';
export { default as SearchIcon } from './flip-ui-kit/small/Search';
export { default as SendIcon } from './flip-ui-kit/small/Send';
export { default as Settings2Icon } from './flip-ui-kit/small/Settings2';
export { default as SettingsIcon } from './flip-ui-kit/small/Settings';
export { default as SettingsSlidersIcon } from './flip-ui-kit/small/SettingsSliders';
export { default as ShieldIcon } from './flip-ui-kit/small/Shield';
export { default as ShieldTickIcon } from './flip-ui-kit/small/ShieldTick';
export { default as ShieldWarningIcon } from './flip-ui-kit/small/ShieldWarning';
export { default as ShipmentCheckIcon } from './flip-ui-kit/small/ShipmentCheck';
export { default as ShuffleIcon } from './flip-ui-kit/small/Shuffle';
export { default as SlantedArrowIcon } from './flip-ui-kit/small/SlantedArrow';
export { default as SleepClockIcon } from './flip-ui-kit/small/SleepClock';
export { default as SortIcon } from './flip-ui-kit/small/Sort';
export { default as SparklesIcon } from './flip-ui-kit/small/Sparkles';
export { default as SpeechBubbleIcon } from './flip-ui-kit/small/SpeechBubble';
export { default as SpinnerIcon } from './flip-ui-kit/small/Spinner';
export { default as StarIcon } from './flip-ui-kit/small/Star';
export { default as SwapIcon } from './flip-ui-kit/small/Swap';
export { default as Switch2Icon } from './flip-ui-kit/small/Switch2';
export { default as SwitchIcon } from './flip-ui-kit/small/Switch';
export { default as TagIcon } from './flip-ui-kit/small/Tag';
export { default as TargetIcon } from './flip-ui-kit/small/Target';
export { default as TelegramIcon } from './flip-ui-kit/small/Telegram';
export { default as ThumbsUpIcon } from './flip-ui-kit/small/ThumbsUp';
export { default as TimePreviousIcon } from './flip-ui-kit/small/TimePrevious';
export { default as TimerIcon } from './flip-ui-kit/small/Timer';
export { default as TrashIcon } from './flip-ui-kit/small/Trash';
export { default as TrendingDownIcon } from './flip-ui-kit/small/TrendingDown';
export { default as TrendingUpIcon } from './flip-ui-kit/small/TrendingUp';
export { default as TrophyIcon } from './flip-ui-kit/small/Trophy';
export { default as TwitterIcon } from './flip-ui-kit/small/Twitter';
export { default as UserCheckIcon } from './flip-ui-kit/small/UserCheck';
export { default as UserIcon } from './flip-ui-kit/small/User';
export { default as UsersIcon } from './flip-ui-kit/small/Users';
export { default as WalletIcon } from './flip-ui-kit/small/Wallet';
export { default as WarningTriangleIcon } from './flip-ui-kit/small/WarningTriangle';
export { default as WindowIcon } from './flip-ui-kit/small/Window';
export { default as WithdrawIcon } from './flip-ui-kit/small/Withdraw';
export { default as WriteNoteIcon } from './flip-ui-kit/small/WriteNote';
export { default as TimeBoost } from './flip-ui-kit/small/TimeBoost';
export { default as SliceIcon } from './flip-ui-kit/small/Slice';
export { default as RefundIcon } from './flip-ui-kit/small/Refund';
export { default as TimeSpeedIcon } from './flip-ui-kit/small/TimeSpeed';
export { default as ChunksIcon } from './flip-ui-kit/small/Chunks';
